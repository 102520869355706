<template>
  <div class="w-100" v-if="form && isLoading">
    <form class="form-box">
      <div class="bg-white p-3">
        <b-row class="no-gutters bg-white mb-2">
          <b-col>
            <b-button v-b-toggle.collapse-general class="collapse-btn">
              ข้อมูลทั่วไป
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-general" visible>
              <b-card class="card-box">
                <div>
                  <div
                    v-for="(item, index) in form.product.translationList"
                    v-bind:key="index"
                  >
                    <div
                      v-bind:class="[
                        languageActive == item.languageId ? '' : 'd-none',
                      ]"
                    >
                      <InputText
                        class="mb-4 mt-2"
                        :textFloat="'ชื่อสินค้า'"
                        :placeholder="'ชื่อสินค้า'"
                        type="text"
                        name="name"
                        isRequired
                        v-model="item.name"
                        @onKeyup="setMeta(item.name, index)"
                        :isValidate="
                          $v.form.product.translationList.$each.$iter[index]
                            .name.$error
                        "
                        :v="
                          $v.form.product.translationList.$each.$iter[index]
                            .name
                        "
                      />
                    </div>
                  </div>
                </div>

                <label class="font-weight-bold main-label">
                  <span class="mr-1">หมวดหมู่</span>
                  <span class="text-danger">*</span>
                </label>
                <CategorySection
                  :dataList="form.product.selectCategory"
                  :catagories="catagories"
                  @onDataChange="onDataChangeCategory"
                  :v="$v.form.product.selectCategory"
                />
                <p class="text-danger" v-if="error">{{ errorText }}</p>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>

      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col>
            <b-button v-b-toggle.collapse-type class="collapse-btn">
              รายละเอียด
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-type" visible>
              <b-card class="card-box">
                <label
                  class="font-weight-bold main-label"
                  v-if="form.product.productTypeId != 4"
                >
                  <span class="mr-1">รายละเอียด</span>
                  <span class="text-danger">*</span>
                </label>

                <b-row class="mt-2 mb-4" v-if="form.product.productTypeId != 4">
                  <b-col md="6" class="mb-1 mb-sm-0">
                    <b-row>
                      <b-col>
                        <b-form-radio
                          name="some-type"
                          value="1"
                          v-model="form.product.productTypeId"
                          @change="handleProducTypeChange"
                          >สินค้าทั่วไป</b-form-radio
                        >
                      </b-col>
                      <b-col>
                        <b-form-radio
                          name="some-type"
                          value="2"
                          v-model="form.product.productTypeId"
                          @change="handleProducTypeChange"
                          >สินค้าแบบมีตัวเลือก
                        </b-form-radio>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row
                  class="mt-2 mb-sm-0"
                  v-if="form.product.productTypeId == 1"
                >
                  <b-col md="6" class="mb-1 mb-sm-0">
                    <InputText
                      textFloat="SKU"
                      placeholder="SKU"
                      type="text"
                      name="sku"
                      isRequired
                      v-model="form.product.sku"
                      :isValidate="$v.form.product.sku.$error"
                      :v="$v.form.product.sku"
                    />
                  </b-col>
                  <b-col md="6" class="mb-1 mb-sm-0">
                    <InputText
                      :textFloat="'จำนวน'"
                      :placeholder="'จำนวน'"
                      type="text"
                      name="quantity"
                      isRequired
                      v-model="form.product.quantity"
                      @onKeypress="isNumber($event)"
                      :isValidate="$v.form.product.quantity.$error"
                      :v="$v.form.product.quantity"
                    />
                  </b-col>
                </b-row>

                <b-row v-if="form.product.productTypeId == 1">
                  <b-col sm="6">
                    <InputText
                      :textFloat="'ราคาเต็ม'"
                      :placeholder="'ราคาเต็ม'"
                      type="text"
                      name="price"
                      isRequired
                      @onKeypress="isNumber($event)"
                      v-model="form.product.straightPrice"
                      :isValidate="$v.form.product.straightPrice.$error"
                      :v="$v.form.product.straightPrice"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      :textFloat="'ราคาขาย'"
                      :placeholder="'ราคาขาย'"
                      type="text"
                      name="price"
                      isRequired
                      v-model="form.product.rawPrice"
                      :isValidate="$v.form.product.rawPrice.$error"
                      :v="$v.form.product.rawPrice"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>
                </b-row>
                <!-- 
              <b-row v-if="form.product.productTypeId == 1">
                <b-col md="6">
                  <InputText
                    textFloat="GP"
                    placeholder="GP"
                    type="text"
                    name="gp"
                    isRequired
                    v-model="form.product.gp"
                    :isValidate="$v.form.product.gp.$error"
                    :v="$v.form.product.gp"
                    :minValidate="form.product.productGP"
                    @onKeypress="isNumber($event, true)"
                  />
                </b-col>
              </b-row> -->

                <b-row v-if="form.product.productTypeId == 2">
                  <b-col md="6">
                    <InputText
                      textFloat="SKU"
                      placeholder="SKU"
                      type="text"
                      name="sku"
                      isRequired
                      v-model="form.product.sku"
                      :isValidate="$v.form.product.sku.$error"
                      :v="$v.form.product.sku"
                      @onKeyup="handleChangeSKU()"
                    />
                  </b-col>
                </b-row>

                <b-row
                  class="mt-5 mb-sm-0"
                  v-if="form.product.productTypeId == 2"
                >
                  <b-col>
                    <ConfigOptionsSection
                      :v="$v.form.product.attributes"
                      :vsku="$v.form.product.sku"
                      :isEdit="form.product.id > 0"
                      :dataList="form.product.attributes"
                      :childrenObject="form.product.children"
                      :productAttributeImage="
                        form.product.productAttributeImage
                      "
                      :parentSKU="form.product.sku"
                      :minGP="form.product.productGP"
                      @handleCreateOptions="handleCreateOptions"
                      @handleCreateOptionsChoice="handleCreateOptionsChoice"
                      @handleChildrenObject="handleChildrenObject"
                      @onChangeOptionsName="onChangeOptionsName"
                      @onChangeOptionsChoiceName="onChangeOptionsChoiceName"
                    />
                  </b-col>
                </b-row>

                <!-- <b-row
                class="mt-5 mb-sm-0"
                v-if="form.product.productTypeId == 2"
                id="combinationTable"
              >
                <b-col>
                  <label class="main-label mb-3" for="CombinationTable"
                    >รายการตัวเลือกสินค้า</label
                  >
                  <span
                    class="text-error"
                    v-if="
                      $v.form.product.children.attributeOption.$error == true
                    "
                  >
                    กรุณากรอกข้อมูลให้ครบถ้วน
                  </span>
                </b-col>
              </b-row> -->

                <b-row class v-if="form.product.productTypeId == 2">
                  <b-col>
                    <CombinationTable
                      class="mt-3"
                      :dataObject="form.product.children"
                      :attributes="form.product.attributes"
                      :productGP="form.product.productGP"
                      :v="$v.form.product.children.attributeOption"
                    />
                    <!-- :v="$v.form.product.children.attributeOption" -->
                  </b-col>
                </b-row>

                <template v-if="form.product.productTypeId == 4">
                  <b-row class="mt-3 mb-sm-0">
                    <b-col md="6" class="mb-1 mb-sm-0">
                      <InputText
                        textFloat="SKU"
                        placeholder="SKU"
                        type="text"
                        name="sku"
                        isRequired
                        v-model="form.product.sku"
                        :isValidate="$v.form.product.sku.$error"
                        :v="$v.form.product.sku"
                      />
                    </b-col>
                    <b-col md="6" class="mb-1 mb-sm-0">
                      <InputText
                        :textFloat="'จำนวน'"
                        :placeholder="'จำนวน'"
                        type="text"
                        name="quantity"
                        isRequired
                        v-model="form.product.quantity"
                        @onKeypress="isNumber($event)"
                        :isValidate="$v.form.product.quantity.$error"
                        :v="$v.form.product.quantity"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="6">
                      <InputText
                        :textFloat="'ราคาเต็ม'"
                        :placeholder="'ราคาเต็ม'"
                        type="text"
                        name="price"
                        isRequired
                        @onKeypress="isNumber($event)"
                        v-model="form.product.straightPrice"
                        :isValidate="$v.form.product.straightPrice.$error"
                        :v="$v.form.product.straightPrice"
                      />
                    </b-col>
                    <!-- <b-col sm="6">
                    <InputText
                      textFloat="GP"
                      placeholder="GP"
                      type="text"
                      name="gp"
                      isRequired
                      v-model="form.product.gp"
                      :isValidate="$v.form.product.gp.$error"
                      :v="$v.form.product.gp"
                      :minValidate="form.product.productGP"
                      @onKeypress="isNumber($event, true)"
                    />
                  </b-col> -->
                  </b-row>
                </template>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>

      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col>
            <b-button v-b-toggle.collapse-images class="collapse-btn">
              <span class="mr-2">รูปภาพ</span>
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-images" visible>
              <b-card class="card-box">
                <DefaultImages
                  @updateImageList="updateImageList"
                  :dataList="form.product.productImage"
                  :imageUrl="form.product.imageUrl"
                  :v="$v.form.product.productImage"
                />

                <div v-if="form.product.productTypeId == 2">
                  <label class="font-weight-bold main-label mb-3">
                    {{ form.product.children.label }}
                  </label>
                  <template v-if="forceRefresh">
                    <draggable
                      :list="form.product.productAttributeImage"
                      handle=".handle"
                      class="img-list m-0 d-flex"
                    >
                      <div
                        class="handle mr-4"
                        :style="[
                          {
                            width: `calc(12%)`,
                          },
                        ]"
                        v-for="(img, index) in form.product
                          .productAttributeImage"
                        :key="index"
                      >
                        <ImageUpload
                          v-bind:dataFile="img"
                          :index="index"
                          :name="img.altTag"
                          :v="
                            $v.form.product.productAttributeImage.$each.$iter[
                              index
                            ].imageUrl
                          "
                          @handleChangeImage="handleChangeImage"
                        />
                      </div>
                    </draggable>
                  </template>
                </div>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col>
            <b-button
              v-b-toggle.collapse-bundle
              class="collapse-btn"
              v-if="form.product.productTypeId == 4"
            >
              ราคาขาย
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-bundle" visible>
              <b-card class="card-box">
                <b-row>
                  <b-col lg="6" md="12" sm="12">
                    <InputText
                      :textFloat="'ราคาขายสินค้า'"
                      :placeholder="'ราคาขายสินค้า'"
                      type="text"
                      name="price"
                      isRequired
                      v-model="form.product.rawPrice"
                      :isValidate="
                        $v.form.product.rawPrice.$error || checkPrice
                      "
                      :v="$v.form.product.rawPrice"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>
                  <b-col lg="6" md="12" sm="12" class="mt-2">
                    <div
                      :class="[
                        productGroups.length > 0
                          ? 'd-flex justify-content-between'
                          : 'd-flex justify-content-end',
                      ]"
                    >
                      <div
                        class="d-flex align-items-center"
                        v-if="productGroups.length > 0"
                      >
                        <b-form-radio
                          name="some-type"
                          value="1"
                          v-model="config"
                          @change="handleTypeChange"
                          >กำหนดราคา</b-form-radio
                        >
                        <b-form-radio
                          class="ml-3"
                          name="some-type"
                          value="2"
                          v-model="config"
                          @change="handleTypeChange"
                          >คำนวนโดยระบบ
                        </b-form-radio>
                      </div>
                      <b-button class="btn-main" @click="openModal()"
                        >เพิ่ม SKU</b-button
                      >
                    </div>
                  </b-col>
                  <span
                    class="input-custom error text-error mx-3"
                    v-if="checkBundle == true"
                    >กรุณาเพิ่มรายการสินค้า Bundle
                  </span>
                </b-row>

                <b-row
                  class="no-gutters table-detail mt-3"
                  v-if="productGroups.length > 0 || id != 0"
                >
                  <b-col>
                    <b-table
                      responsive
                      class="text-center table-list"
                      striped
                      :fields="fieldSelectedData"
                      :items="productGroups"
                      show-empty
                      empty-text="ไม่พบข้อมูล"
                    >
                      <template v-slot:cell(ids)="data">
                        <b-form-checkbox
                          size="lg"
                          class="ml-3"
                          :value="data.item.id"
                          v-model="selected"
                          @input="handleItem(data.item.id)"
                        ></b-form-checkbox>
                      </template>
                      <template v-slot:cell(name)="data">
                        <p class="mb-0 nobreak two-lines">
                          {{ data.item.name }}
                        </p>
                      </template>
                      <template v-slot:cell(sku)="data">
                        <p class="mb-0 nobreak two-lines">
                          {{ data.item.sku }}
                        </p>
                      </template>
                      <template v-slot:cell(price)="data">
                        <input
                          v-if="config == 2"
                          :class="['custom-input']"
                          type="text"
                          name="price"
                          isRequired
                          disabled
                          v-model="data.item.price"
                          @onKeypress="isNumber($event)"
                        />
                        <input
                          v-else
                          :class="['custom-input']"
                          type="text"
                          name="price"
                          isRequired
                          v-model="data.item.price"
                          @onKeypress="isNumber($event)"
                        />
                      </template>
                      <template v-slot:cell(quantity)="data">
                        <input
                          :class="['custom-input']"
                          type="text"
                          name="quantity"
                          isRequired
                          v-model="data.item.quantity"
                          @onKeypress="isNumber($event)"
                        />
                      </template>
                      <template v-slot:cell(action)="data">
                        <font-awesome-icon
                          @click="deleteData(data.item.id)"
                          icon="times-circle"
                          class="text-secondary delete-icon pointer"
                        />
                      </template>
                    </b-table>
                    <span
                      class="input-custom error text-error"
                      v-if="checkPrice == true"
                      >กรุณากรอกราคาสินค้าให้เท่ากับราคาขาย
                    </span>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col>
            <b-button v-b-toggle.collapse-delivery class="collapse-btn">
              ขนาดของสินค้า
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-delivery" visible>
              <b-card class="card-box">
                <b-row>
                  <b-col lg="3" md="6">
                    <InputText
                      :textFloat="'น้ำหนัก'"
                      :placeholder="'น้ำหนัก'"
                      type="text"
                      name="weight"
                      isRequired
                      v-model="form.product.weight"
                      :isValidate="$v.form.product.weight.$error"
                      :v="$v.form.product.weight"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>

                  <b-col lg="3" md="6">
                    <InputText
                      :textFloat="'ความยาว'"
                      :placeholder="'ความยาว'"
                      type="text"
                      name="length"
                      isRequired
                      v-model="form.product.length"
                      :isValidate="$v.form.product.length.$error"
                      :v="$v.form.product.length"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>

                  <b-col lg="3" md="6">
                    <InputText
                      :textFloat="'ความกว้าง'"
                      :placeholder="'ความกว้าง'"
                      type="text"
                      name="width"
                      isRequired
                      v-model="form.product.width"
                      :isValidate="$v.form.product.width.$error"
                      :v="$v.form.product.width"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>

                  <b-col lg="3" md="6">
                    <InputText
                      :textFloat="'ความสูง'"
                      :placeholder="'ความสูง'"
                      type="text"
                      name="height"
                      isRequired
                      v-model="form.product.height"
                      :isValidate="$v.form.product.height.$error"
                      :v="$v.form.product.height"
                      @onKeypress="isNumber($event)"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <div class="w-100 p-0">
            <b-row class="pl-2">
              <b-col cols="6">
                <div class="panel">
                  <b-form-checkbox
                    size="lg"
                    id="changeLang"
                    class="mt-0 mt-sm-2"
                    @change="useSameLanguage"
                    v-model="form.product.isSameLanguage"
                    >ใช้เหมือนกันทุกภาษา
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col cols="6" class="text-right">
                <div class="panel mt-2">
                  <b-button
                    type="button"
                    class="btn btn-language"
                    v-for="(language, index) in languageList"
                    v-bind:key="index"
                    v-bind:class="[
                      languageActive == language.id ? 'active' : '',
                    ]"
                    @click="changeLanguage(language.id, index)"
                    :disabled="form.product.isSameLanguage ? true : false"
                  >
                    <span class="text-uppercase">{{ language.nation }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-row>
        <b-row class="no-gutters mt-3">
          <b-col>
            <SEOSection
              :data="form.product.translationList"
              :img="imageLogoLang"
              :isDisabled="false"
              :langActive="languageActive"
              :isValidate="$v.form.product.translationList"
              :v="$v.form.product.translationList"
            />
          </b-col>
        </b-row>
      </div>
      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col>
            <b-button v-b-toggle.collapse-description class="collapse-btn">
              จัดการคำบรรยาย
              <font-awesome-icon
                icon="chevron-right"
                class="icon float-right mt-1"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="icon float-right mt-1"
              />
            </b-button>
            <b-collapse id="collapse-description" visible>
              <b-card class="card-box description">
                <ProductDescriptions
                  ref="productDescriptions"
                  :form="form"
                  :imageLogoLang="imageLogoLang"
                  :languageActive="languageActive"
                  :isValidate="$v.form.product.translationList"
                  :v="$v.form.product.translationList"
                />
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white p-3 mt-3">
        <b-row class="no-gutters">
          <b-col md="12">
            <b-row class="px-3">
              <b-col
                sm="6"
                class="mb-4 mb-sm-0 p-0 d-flex justify-content-start align-items-center"
              >
                <label class="font-weight-bold main-label">
                  การใช้งาน
                  <span class="text-danger">*</span>
                </label>
                <div>
                  <b-form-checkbox
                    switch
                    v-model="form.product.enabled"
                    class="radio-active ml-3"
                    size="lg"
                  >
                    <span class="ml-2 main-label">
                      {{ form.product.enabled ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col sm="6" class="d-flex p-0">
                <b-col class="d-flex justify-content-end p-0">
                  <router-link :to="'/product'">
                    <b-button class="button btn-cancel ml-2"
                      >ย้อนกลับ</b-button
                    ></router-link
                  >
                  <b-button class="button btn-main ml-2" @click="syncProduct()"
                    >Sync Product</b-button
                  >
                  <b-button class="btn-main ml-2" @click="checkForm(true)"
                    >บันทึก</b-button
                  >
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </form>
    <ModalSku ref="ModalSku" @handleData="handleData" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import InputText from "@/components/inputs/InputText";
import CategorySection from "@/views/pages/product/components/CategorySection";
import ConfigOptionsSection from "@/views/pages/product/components/ConfigOptionsSection";
import CombinationTable from "@/views/pages/product/components/CombinationListSection";
import DefaultImages from "@/views/pages/product/components/DefaultImages";
import ImageUpload from "@/views/pages/product/components/ImageUpload";
import SEOSection from "@/views/pages/product/components/SEOSection";
import ModalSku from "@/views/pages/product/components/ModalSku";
import draggable from "vuedraggable";
import Vue from "vue";
import ProductDescriptions from "@/views/pages/product/components/ProductDescriptions";

const validateGP = (typeId, value, rawPrice, minGp) => {
  if (typeId == 1 && value == 0) {
    return false;
  } else if (typeId == 1 && value < (minGp * rawPrice) / 100) {
    return false;
  } else {
    return true;
  }
};

const validateMinGP = (typeId, value, minGp) => {
  if (typeId == 1 && value < minGp) {
    return false;
  } else {
    return true;
  }
};

const validateGPConfig = (value) => {
  if (value == 0) {
    return false;
  } else {
    return true;
  }
};

const validateMinGPConfig = (value, minGp) => {
  if (value < minGp) {
    return false;
  } else {
    return true;
  }
};

const validateMinvalue = (value) => {
  if (parseInt(value) == 0) {
    return true;
  } else {
    return false;
  }
};

import {
  required,
  requiredIf,
  minValue,
  minLength,
  decimal,
} from "vuelidate/lib/validators";

export default {
  name: "ProductDetailSection",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ProductDescriptions,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    InputText,
    CategorySection,
    ConfigOptionsSection,
    CombinationTable,
    DefaultImages,
    ImageUpload,
    SEOSection,
    ModalSku,
    draggable,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: true,
      form: null,
      languageList: [],
      catagories: [],
      languageActive: 1,
      imageLogoLang: "",
      error: false,
      errorText: "",
      config: 1,
      modalMessage: "",
      dataCategoryHierarchy: {
        categoryList: [],
        isLast: false,
        selectId: 0,
      },
      optionsImageList: [],
      productGroups: [],
      fieldSelectedData: [
        {
          key: "name",
          label: "Product Detail",
          class: "w-100px text-nowrap",
        },
        { key: "sku", label: "SKU", class: "w-100px text-nowrap" },
        { key: "price", label: "ราคาขาย", class: "w-100px text-nowrap" },
        { key: "quantity", label: "Qty", class: "w-100px text-nowrap" },
        { key: "action", label: "", class: "w-50px text-nowrap" },
      ],
      forceRefresh: true,
      checkPrice: false,
      checkBundle: false,
    };
  },
  created: async function () {
    await this.getData();
    await this.changeLanguage(1, 0);
    if (this.id == 1) {
      await this.handleProducTypeChange(4);
      this.form.product.productTypeId = 4;
    }
    await this.$emit("updateProductTypeId", this.form.product.productTypeId);
  },
  validations: {
    form: {
      product: {
        rawPrice: {
          required: requiredIf(function () {
            return (
              this.form.product.productTypeId == 1 ||
              this.form.product.productTypeId == 4
            );
          }),
          validateMinvalue: function (value) {
            return !(
              validateMinvalue(value) &&
              (this.form.product.productTypeId == 1 ||
                this.form.product.productTypeId == 4)
            );
          },
        },
        straightPrice: { required },
        sku: { required },
        // gp: {
        //   required: requiredIf(function() {
        //     return this.form.product.productTypeId == 1;
        //   }),
        //   validateGP(value) {
        //     return validateGP(this.form.product.productTypeId, value);
        //   },
        //   validateMinGP(value) {
        //     return validateMinGP(
        //       this.form.product.productTypeId,
        //       value,
        //       this.form.product.productGP
        //     );
        //   },
        // },
        quantity: {
          required: requiredIf(function () {
            return this.form.product.productTypeId == 1;
          }),
        },
        height: { required, decimal, minValue: minValue(1) },
        weight: { required, decimal, minValue: minValue(1) },
        width: { required, decimal, minValue: minValue(1) },
        length: { required, decimal, minValue: minValue(1) },
        urlKey: { required },
        selectCategory: { required },
        productImage: { required },
        attributes: {
          $each: {
            label: {
              required: requiredIf(function () {
                return this.form.product.productTypeId == 2;
              }),
            },
            option: {
              $each: {
                label: {
                  required: requiredIf(function () {
                    return this.form.product.productTypeId == 2;
                  }),
                },
              },
            },
          },
        },
        children: {
          attributeOption: {
            $each: {
              product: {
                rawPrice: {
                  required: requiredIf(function () {
                    return (
                      this.form.product.productTypeId == 2 &&
                      this.form.product.attributes.length === 1
                    );
                  }),
                  validateMinvalue: function (value) {
                    return !(
                      validateMinvalue(value) &&
                      this.form.product.productTypeId == 2 &&
                      this.form.product.attributes.length === 1
                    );
                  },
                },
                straightPrice: {
                  required: requiredIf(function () {
                    return (
                      this.form.product.productTypeId == 2 &&
                      this.form.product.attributes.length === 1
                    );
                  }),
                },
                sku: {
                  required: requiredIf(function () {
                    return (
                      this.form.product.productTypeId == 2 &&
                      this.form.product.attributes.length === 1
                    );
                  }),
                },
                quantity: {
                  required: requiredIf(function () {
                    return (
                      this.form.product.productTypeId == 2 &&
                      this.form.product.attributes.length === 1
                    );
                  }),
                },
                // gp: {
                //   required: requiredIf(function() {
                //     return (
                //       this.form.product.productTypeId == 2 &&
                //       this.form.product.attributes.length === 1
                //     );
                //   }),
                //   validateGPConfig(value) {
                //     if (
                //       this.form.product.productTypeId == 2 &&
                //       this.form.product.attributes.length === 1
                //     ) {
                //       return (
                //         this.form.product.attributes.length === 1 &&
                //         validateGPConfig(value)
                //       );
                //     } else {
                //       return true;
                //     }
                //   },
                //   validateMinGPConfig(value, form) {
                //     if (
                //       this.form.product.productTypeId == 2 &&
                //       this.form.product.attributes.length === 1
                //     ) {
                //       return (
                //         this.form.product.attributes.length === 1 &&
                //         validateMinGPConfig(value, this.form.product.productGP)
                //       );
                //     } else {
                //       return true;
                //     }
                //   },
                // },
              },
              attribute: {
                attributeOption: {
                  $each: {
                    product: {
                      rawPrice: {
                        required: requiredIf(function () {
                          return (
                            this.form.product.productTypeId == 2 &&
                            this.form.product.attributes.length === 2
                          );
                        }),
                        validateMinvalue: function (value) {
                          return !(
                            validateMinvalue(value) &&
                            this.form.product.productTypeId == 2 &&
                            this.form.product.attributes.length === 2
                          );
                        },
                      },
                      straightPrice: {
                        required: requiredIf(function () {
                          return (
                            this.form.product.productTypeId == 2 &&
                            this.form.product.attributes.length === 2
                          );
                        }),
                      },
                      sku: {
                        required: requiredIf(function () {
                          return (
                            this.form.product.productTypeId == 2 &&
                            this.form.product.attributes.length === 2
                          );
                        }),
                      },
                      quantity: {
                        required: requiredIf(function () {
                          return (
                            this.form.product.productTypeId == 2 &&
                            this.form.product.attributes.length === 2
                          );
                        }),
                      },
                      // gp: {
                      //   required: requiredIf(function() {
                      //     return (
                      //       this.form.product.productTypeId == 2 &&
                      //       this.form.product.attributes.length === 2
                      //     );
                      //   }),
                      //   validateGPConfig(value) {
                      //     if (
                      //       this.form.product.productTypeId == 2 &&
                      //       this.form.product.attributes.length === 2
                      //     ) {
                      //       return validateGPConfig(value);
                      //     } else {
                      //       return true;
                      //     }
                      //   },
                      //   validateMinGPConfig(value, form) {
                      //     if (
                      //       this.form.product.productTypeId == 2 &&
                      //       this.form.product.attributes.length === 2
                      //     ) {
                      //       return validateMinGPConfig(
                      //         value,
                      //         this.form.product.productGP
                      //       );
                      //     } else {
                      //       return true;
                      //     }
                      //   },
                      // },
                    },
                    attribute: {
                      attributeOption: {
                        $each: {
                          product: {
                            rawPrice: {
                              required: requiredIf(function () {
                                return (
                                  this.form.product.productTypeId == 2 &&
                                  this.form.product.attributes.length === 3
                                );
                              }),
                              validateMinvalue: function (value) {
                                return !(
                                  validateMinvalue(value) &&
                                  this.form.product.productTypeId == 2 &&
                                  this.form.product.attributes.length === 3
                                );
                              },
                            },
                            straightPrice: {
                              required: requiredIf(function () {
                                return (
                                  this.form.product.productTypeId == 2 &&
                                  this.form.product.attributes.length === 3
                                );
                              }),
                            },
                            sku: {
                              required: requiredIf(function () {
                                return (
                                  this.form.product.productTypeId == 2 &&
                                  this.form.product.attributes.length === 3
                                );
                              }),
                            },
                            quantity: {
                              required: requiredIf(function () {
                                return (
                                  this.form.product.productTypeId == 2 &&
                                  this.form.product.attributes.length === 3
                                );
                              }),
                            },
                            // gp: {
                            //   required: requiredIf(function() {
                            //     return (
                            //       this.form.product.productTypeId == 2 &&
                            //       this.form.product.attributes.length === 3
                            //     );
                            //   }),
                            //   validateGPConfig(value) {
                            //     if (
                            //       this.form.product.productTypeId == 2 &&
                            //       this.form.product.attributes.length === 3
                            //     ) {
                            //       return validateGPConfig(value);
                            //     } else {
                            //       return true;
                            //     }
                            //   },
                            //   validateMinGPConfig(value, form) {
                            //     if (
                            //       this.form.product.productTypeId == 2 &&
                            //       this.form.product.attributes.length === 3
                            //     ) {
                            //       return validateMinGPConfig(
                            //         value,
                            //         this.form.product.productGP
                            //       );
                            //     } else {
                            //       return true;
                            //     }
                            //   },
                            // },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        productAttributeImage: {
          $each: {
            imageUrl: {
              required: requiredIf(function () {
                return this.form.product.productTypeId == 2;
              }),
            },
          },
        },
        translationList: {
          $each: {
            name: { required, minLength: minLength(3) },
            metaTitle: { required },
            metaKeyword: { required },
            metaDescription: { required },
            // description: { required },
          },
        },
      },
    },
  },
  watch: {
    form: {
      async handler() {
        await this.checkPriceBundle();
      },
      deep: true,
    },
    productGroups: {
      async handler(e) {
        this.checkBundle = false;
        this.form.product.productGroups = e;
        await this.checkPriceBundle();
      },
      deep: true,
    },
  },
  methods: {
    async getData() {
      this.$isLoading = false;
      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );

      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let cat = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/categoryHierarchy`,
        null,
        this.$headers,
        null
      );

      if (cat.result == 1) {
        this.catagories = cat.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/product/productDetail/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        if (this.id == 1) {
          this.form.product.flagIsAutoPriceRatio = true;
        }
        this.$v.form.$reset();

        if (this.id == 0) {
          this.form.product.productTypeId = 1;
          this.form.product.attributes = [];
          this.form.product.children = { attributeOption: [] };
          this.form.product.productAttributeImage = [];
        }

        if (this.form.product.isSameLanguage) {
          this.imageLogoLang = "";
          this.languageActive = this.form.product.mainLanguageId;
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.form.product.mainLanguageId);
          if (index != -1) {
            this.imageLogoLang = this.languageList[index].imageUrl;
          }
        }

        if (this.form.product.gp == 0) {
          this.form.product.gp = this.form.product.productGP;
        }

        this.$isLoading = true;
      }
    },
    async checkPriceBundle() {
      if (
        this.form.product.productGroups &&
        this.form.product.productGroups.length > 0 &&
        parseInt(this.config) === 1
      ) {
        let sum = 0;
        for (const i of this.form.product.productGroups) {
          sum += i.price * i.quantity;
        }
        if (parseFloat(sum) !== parseFloat(this.form.product.rawPrice))
          this.checkPrice = true;
        else this.checkPrice = false;
      }
    },
    changeLanguage(id, index) {
      if (!this.form.product.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    async checkForm(flag) {
      var catLength = this.form.product.selectCategory.length;
      if (this.form.product.isSameLanguage) {
        await this.useSameLanguage();
      }
      if (
        this.form.product.children &&
        this.form.product.children.attributeOption.length
      ) {
        for (const i of this.form.product.children.attributeOption) {
          i.product.gp = 0;
        }
      }

      this.$v.form.$touch();
      if (this.id == 1) {
        if (
          !this.form.product.productGroups ||
          this.form.product.productGroups.length == 0
        ) {
          this.checkBundle = true;
          this.$nextTick(() => {
            let target = document.querySelector(".text-error");
            if (target) {
              let domRect = document
                .querySelector(".text-error")
                .getBoundingClientRect();
              window.scrollTo(
                domRect.left + document.documentElement.scrollLeft,
                domRect.top + document.documentElement.scrollTop - 300
              );
            }
          });
        }

        if (this.checkPrice) {
          this.$nextTick(() => {
            let target = document.querySelector(".text-error");
            if (target) {
              let domRect = document
                .querySelector(".text-error")
                .getBoundingClientRect();
              window.scrollTo(
                domRect.left + document.documentElement.scrollLeft,
                domRect.top + document.documentElement.scrollTop - 300
              );
            }
          });
        }
      }

      if (this.$v.form.$error) {
        if (this.dataCategoryHierarchy.isLast != true) {
          this.errorText = "กรุณาเลือกหมวดหมู่ย่อยทุกระดับ";
          window.scrollTo(0, 150);
        }

        if (this.$v.form.product.children.attributeOption.$error == true) {
          window.scrollTo(0, 150);
        }

        this.$nextTick(() => {
          let target = document.querySelector(".input-custom.error");
          if (target) {
            let domRect = document
              .querySelector(".input-custom.error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          }
        });
        await this.checkValidateTranslationList();
        return;
      }

      this.$refs.modalLoading.show();
      if (catLength > 0) {
        if (this.dataCategoryHierarchy.isLast != true) {
          window.scrollTo(0, 150);
          this.errorText = "กรุณาเลือกหมวดหมู่ย่อยทุกระดับ";
          this.error = true;
          return;
        } else {
          this.error = false;
          await this.form.product.productImage.forEach((item, index) => {
            item.sortOrder = index + 1;
          });

          if (flag == 1) this.form.product.isPublish = true;
          else this.form.product.isPublish = false;

          let resData = await this.$callApi(
            "post",
            `${this.$baseUrl}/api/product/productDetail/save`,
            null,
            this.$headers,
            this.form
          );
          this.$refs.modalLoading.hide();

          if (resData.result == 1) {
            this.modalMessage = "สำเร็จ";
            this.$refs.modalAlert.show();
            setTimeout(() => {
              this.$router.push({ path: `/product` });
            }, 3000);
          } else {
            this.modalMessage = resData.detail[0];
            this.$refs.modalAlertError.show();
            setTimeout(() => {
              this.$refs.modalAlertError.hide();
            }, 2000);
          }
        }
      }
    },
    async syncProduct() {
      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/ForceUpdateZort/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.$refs.modalLoading.hide();

      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    async checkValidateTranslationList() {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.product.translationList.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.product.translationList.$model[index].languageId;
            this.imageLogoLang = this.languageList[index].imageUrl;
            isError = true;
          }
        }
      });
    },
    async useSameLanguage() {
      Vue.nextTick(() => {
        if (this.form.product.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.product.mainLanguageId = this.languageActive;
          let data = this.form.product.translationList.filter(
            (val) => val.languageId == this.form.product.mainLanguageId
          );
          if (this.id == 0 || this.id == 1) {
            if (data.length == 1) {
              data = data[0];
              for (
                let index = 0;
                index < this.form.product.translationList.length;
                index++
              ) {
                this.form.product.translationList[index].name = data.name;
                this.form.product.translationList[index].description =
                  data.description;
                this.form.product.translationList[index].metaTitle =
                  data.metaTitle;
                this.form.product.translationList[index].metaKeyword =
                  data.metaKeyword;
                this.form.product.translationList[index].metaDescription =
                  data.metaDescription;
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;
          let data = this.form.product.translationList.filter(
            (val) => val.languageId != this.form.product.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.name = "";
              data.description = "";
              data.metaTitle = "";
              data.metaKeyword = "";
              data.metaDescription = "";
            }
          }
        }
      });
    },
    handleData(data) {
      if (this.productGroups.length > 0) {
        for (let index = 0; index < data.length; index++) {
          let find = this.productGroups.findIndex((e) => {
            return e.id === data[index].id;
          });
          if (find === -1) this.productGroups.push(data[index]);
        }
      } else this.productGroups = data;
    },
    deleteData(id) {
      for (let index = 0; index < this.productGroups.length; index++) {
        if (this.productGroups[index].id === id) {
          this.productGroups.splice(index, 1);
        }
      }
    },
    openModal() {
      this.$refs.ModalSku.show();
      this.$refs.ModalSku.selected = [];
    },
    setMeta(name, index) {
      if (name) {
        this.form.product.translationList[index].metaTitle = name;
        // this.form.product.translationList[index].metaKeyword = name;
        // this.form.product.translationList[index].metaDescription = name;
        this.form.product.urlKey = name.replace(/ /g, "-").replace(/\//g, "");
      }
    },
    onDataChangeCategory(list) {
      this.dataCategoryHierarchy = { ...list };
      this.form.product.selectCategory = [...list.categoryList];
    },
    async handleTypeChange(value) {
      this.config = value;
      if (parseInt(value) == 2) {
        this.form.product.flagIsAutoPriceRatio = false;
        this.checkPrice = false;
      } else {
        this.form.product.flagIsAutoPriceRatio = true;
        await this.checkPriceBundle();
      }
    },
    async handleProducTypeChange(value) {
      if (value == 2) {
        this.form.product.attributes = [
          {
            code: "",
            label: "",
            option: [
              {
                code: "",
                label: "",
              },
            ],
          },
        ];
        this.form.product.children = {
          attributeOption: [
            {
              id: 0,
              code: "",
              label: "",
              sortOrder: 0,
              attributeMapperId: 0,
              attribute: null,
              product: {
                id: 0,
                rawPrice: 0.0,
                straightPrice: 0.0,
                sku: "",
                quantity: 0,
                gp: "",
                imageUrl: "",
              },
            },
          ],
        };
      } else {
        if (!this.form.product.attributes) {
          this.form.product.attributes = [];
        }
        if (!this.form.product.children) {
          this.form.product.children = { attributeOption: [] };
          this.form.product.productAttributeImage = [];
        }
      }
      this.form.product.productTypeId = value;
    },
    isNumber: function (evt, isGp) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        if (charCode == 46 && !isGp) return true;
        else evt.preventDefault();
      } else {
        return true;
      }
    },
    handleChangeSKU: function (evt) {
      if (this.form.product.productTypeId == 2) {
        if (this.form.product.attributes.length == 1) {
          this.form.product.children.attributeOption.forEach(
            (element, index) => {
              element.product.sku =
                this.form.product.sku + "-000" + (index + 1);
            }
          );
        } else if (this.form.product.attributes.length == 2) {
          let pcount = 0;
          this.form.product.children.attributeOption.forEach((element) => {
            element.attribute.attributeOption.forEach((element2) => {
              pcount++;
              element2.product.sku = this.form.product.sku + "-000" + pcount;
            });
          });
        } else if (this.form.product.attributes.length == 3) {
          let pcount = 0;
          this.form.product.children.attributeOption.forEach((element) => {
            element.attribute.attributeOption.forEach((element2) => {
              element2.attribute.attributeOption.forEach((element3) => {
                pcount++;
                element3.product.sku = this.form.product.sku + "-000" + pcount;
              });
            });
          });
        }
      }
    },
    onChangeOptionsChoiceName(list) {
      this.optionsImageList = list;
    },
    onChangeOptionsName(list) {
      this.optionsImageList = list;
    },
    handleChildrenObject(children, imageList) {
      this.form.product.children = children;
      this.form.product.productAttributeImage = imageList;
      this.handleForcefresh();
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    handleCreateOptions(list) {
      this.optionsImageList = list;
    },
    handleCreateOptionsChoice(list) {
      this.optionsImageList = list;
    },
    updateImageList(value, flag) {
      if (flag) this.form.product.imageUrl = value;
      else this.form.product.productImage = value;
    },
    handleChangeImage(index, image) {
      this.form.product.productAttributeImage[index].imageUrl = image;
    },
  },
};
</script>

<style scoped>
.custom-input {
  display: block;
  border: none;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #bcbcbc;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}

::v-deep .card {
  margin-bottom: 0 !important;
}

::v-deep .description .card-body {
  padding: 0 1.25rem !important;
}
</style>
