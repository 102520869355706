<template>
  <div class="w-100" v-if="$isLoading && form">
    <div class="p-0">
      <b-row>
        <b-col class="mb-0">
          <div
            v-for="(item, index) in form.product.translationList"
            v-bind:key="index"
          >
            <div
              v-bind:class="[languageActive == item.languageId ? '' : 'd-none']"
            >
              <b-row class="mt-3">
                <b-col>
                  <TextEditor
                    textFloat="คำอธิบาย"
                    :rows="8"
                    :value="item.description"
                    :name="'description_' + item.languageId"
                    :img="imageLogoLang"
                    placeholder="Type something..."
                    @onDataChange="(val) => onChangeDescription(val, index)"
                    :imgTypeId="imgTypeId"
                  />
                  <!-- :isValidate=" isValidate.$each.$iter[index].description.$error
                  " :v="v.$each.$iter[index].description" -->
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TextEditor from "@/components/inputs/TextEditor";
import Vue from "vue";

export default {
  components: {
    TextEditor,
  },
  props: {
    form: {
      required: false,
      type: [Array, Object],
    },
    imageLogoLang: {
      required: false,
      type: String,
    },
    languageActive: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      imgTypeId: 15,
    };
  },
  // watch: {
  //   form: {
  //     async handler() {
  //       for (const e of this.form.product.translationList) {
  //         e.name = e.metaTitle;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  // created: async function () {
  //   this.init();
  // },
  methods: {
    // async init() {
    //   this.$isLoading = false;
    //   Vue.nextTick(async () => {
    //     this.$isLoading = true;
    //   });
    // },
    onChangeDescription(val, index) {
      this.form.product.translationList[index].description = val;
    },
  },
};
</script>
